

.calender-update-container{
    display: flex;
    /* background: #000; */
    /* margin: 50px; */
    }
    
    /* .calender-update-content{
    background-color: #f8f8f8;
    color: black;
    overflow: hidden;
    transition: all .5s linear;
    line-height: 50px;
    height: 0;
    }
    
    .calender-update-content:target{
        height: 50px;
    } */