.modal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}
.aminities-text {
  margin-bottom: 15px;
}
.amenities-container {
  /* display: flex;
    justify-content: space-evenly; */
}
.modal-amenities-text-rating-wrapper {
  display: flex;
  justify-content: space-between;
}
.amenities-rating-cont > div {
  display: flex;
  justify-content: flex-end;
}
.modal-rating {
  width: 70px;
  height: 35px;
  /* background-color: #57E32C; */
  background-color: rgb(46, 125, 50);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.amenites-single {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.modal-room-image > img {
  width: 250px;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 4px;
}
.modal-image-gallery-cont {
  position: absolute;
  padding: 5px;
  top: 60%;
  left: 67%;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.modal-gallery-cont {
  display: none;
  position: absolute;
  width: 100%;
  height: 95vh;
  z-index: 9999999;
}
.display-gallery {
  display: block;
}
.modal-gallery-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}
.modal-gallery-close {
  position: absolute;
  top: 30%;
  left: 50%;
  color: black;
  font-weight: 700;
  z-index: 999999;
}
/* .p-text-room1 {
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: 600;
} */
.amenities-container-list {
  display: grid;
  grid-template-columns: repeat(2, 140px);
  column-gap: 20px;
  row-gap: 10px;
}
