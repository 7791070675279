.login-page-form-wrapper {
  width: fit-content;
  margin: auto;
  padding: 2rem;
  margin-top: 5rem;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.form-fields-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.login-page-container {
  /* height: 100vh; */
}
.span-input {
  width: -webkit-fill-available;
  padding: 5px;
}
.input-otp-box {
  width: 100%;
  margin: auto;
  /* text-align: center; */
  padding: 5px;
  font-size: 16px;
  height: 35px;
  font-weight: 500;
  margin-top: 10px;
}
.check-login-btn1 {
  border-radius: 4px;
  background-color: var(--secondary-color);
  border: none;
  color: #fff;
  text-align: center;
  font-size: 14px;
  /* padding: 16px; */
  /* width: 100%; */
  transition: all 0.5s;
  cursor: pointer;
  padding: 10px 25px;
  text-transform: uppercase;
  margin: 15px 15px 15px 5px;
  box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.7);
}

.check-login-btn1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.form-span {
  text-align: justify;
  padding: 5px;
  font-size: 18px;
  font-family: sans-serif;
}
/* .login-otp-container {
    background-color: #ffffff;
  } */
.otp-wrap-cont-fields {
  width: 40%;
  margin: auto;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 10px;
  margin-top: 5rem;
  padding-bottom: 20px;
  border-radius: 4px;
}
.otp-cont-enter {
  display: flex;
  justify-content: center;
  /* width: 40%; */

  background-color: #ffffff;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  margin: 10px;
  /* border: 1px solid red; */
}
.title-otp-header-otp {
  margin: 10px;
}

/* -------------------------------------- */
.box {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #504c4c);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: bg-spin 4s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
.box:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

/* ---------------------------------------  */

@media (max-width: 650px) {
  .total-container {
    margin-top: 50px;
  }

  .otp-wrap-cont-fields {
    width: 100%;
  }
}
