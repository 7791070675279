.booking-left-right-cont {
  display: flex;
  justify-content: center;
  /*  align-items: center; */
  background-color: #f8f8f8 !important;
    width: 67%;
    margin: auto;
} 

.booking-left-cont {
  width: 500px;
  height: 380px;
  /* border: 1px solid black; */
  background-color: #f8f8f8 !important;
}

.booking-right-cont{
  width: 34%;
}
.select-room-text-t{
  width: 66.7%;
}

@media (max-width: 650px) {
.booking-left-right-cont {

  width: 100%;
  flex-direction: column;
}

.booking-right-cont {
  width: 100%;
  margin-top: 50px;
}

.booking-left-cont {
  width: 100%;
  /* height: 0px; */
  /* border: 1px solid black; */
  background-color: #f8f8f8 !important;
  margin-bottom: 60px;
}

.select-room-text-t{
  width: 100%;
}
}