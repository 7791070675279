.container-calendar-guest {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.select-room-text {
  /* color: #F62A00; */
  font-size: 18px;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
  color: #ffffff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* background: linear-gradient(to top, #4d4d4d 0, #6f6f6f 100%); */
  background: var(--secondary-color);
  text-align: left;
  padding: 8px;
}
.filter-container {
  margin-bottom: 30px;
}

.css-1gkse3u {
  width: 400px;
}

@media (max-width: 650px) {
  .css-1gkse3u {
    width: 100%;
  }
}
