.booking-pay-hotel-partial {
  /* display: flex; */
  /* justify-content: space-around; */
  /* align-items: center; */
}
.pay-button {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-around; */
  /* align-items: s */
  /* align-items: stretch; */
  justify-content: center;
}
.payhotel-container {
  display: flex;
  flex-direction: column;
}

.pay-hotel-btn button {
  padding: 8px;
  margin: 10px 8px 0 0;

  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background: var(--secondary-color);
}

.payhotel-container p,
.pay-partial-container p {
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 90%;
  /* margin: auto; */
  margin: 20px auto;
}
