.voucher-stay-div {
  width: 80%;
  margin: auto;
  margin-top: 100px;
  text-align: left;
}
.confirm h2 {
  font-size: 30px;
}

.confirm-sub-text p {
  font-size: 12px;
  margin-bottom: 30px;
}

.main-section-shadow {
  width: 900px;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 20px;
  padding: 15px 0;
}

.header-section-row {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.hotel-details-section-row {
  display: flex;
  justify-content: space-between;
}

.left-column-flex {
  text-align: left;
  /* margin: auto; */
  width: 50%;
  padding: 15px;
}

.user-details-section-row-flex {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  text-align: justify;
}

.left-column-flex-des {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.shadow {
  /* display: flex; */

  width: 100%;
  margin: auto;
  align-items: center;
  padding: 25px;
}

.voucher-img-htl {
  width: 275px;
  height: 150px;
}
.payment-details-main-container {
  width: 100%;
}
@media (max-width: 650px) {
  .voucher-stay-div {
    width: 90%;
    margin: auto;
    margin-top: 80px;
    text-align: left;
  }
  .confirm h2 {
    font-size: 20px;
  }

  .confirm-sub-text p {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .main-section-shadow {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    padding: 0px 0;
  }

  .header-section-row {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  .hotel-details-section-row {
    display: flex;
    flex-direction: column;
  }

  .left-column-flex {
    text-align: left;
    /* margin: auto; */
    width: 100%;
    padding: 15px;
  }

  .user-details-section-row-flex {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-align: justify;
    flex-direction: column;
  }

  .left-column-flex-des {
    width: 100%;
    flex-direction: column;
  }

  .shadow {
    width: 100%;
    text-align: justify;
    font-size: 14px;
  }

  .voucher-img-htl {
    width: 250px;
    height: 240px;
  }

  .payment-details-main-container {
    width: 100%;
  }
  .checkintime-voucher {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
}
