.layout-div-cont {
  background-color: #ffffff;
  transition: border 0.1s linear;
}
.page-content {
  width: 90%;
  margin: auto;
  /* border: 1px solid red; */
  /* height: 900px; */
  margin-top: 5rem;
}
.container-left-right {
  display: flex;
  justify-content: space-between;

  /* border: 1px solid blue; */
}
.container-left {
  width: 65%;
  /* background-color: #e98074; */
  /* height: 800px; */
  /* border: 1px solid green; */
  /* background-color: white; */
}
.container-right {
  width: 33%;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 12%;
  /* margin-top: 2rem; */
  /* border: 1px solid rgb(0, 255, 60); */
}
.container-calendar-guest {
  display: flex;
  justify-content: center;
}
/* .select-room-text {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
} */
.routes-container-width {
  width: 100%;
  margin: auto;
  /* background-color:#e6e6e6; */
  /* background-image: linear-gradient(to right top, #bdc3c7, #96a0a7, #707e89, #4d5d6c, #50565c); */
}
.footer-container {
  width: 100%;
  /* height: 200px; */
  background: var(--secondary-color);
  /* background: var(--secondary-color); */
  margin-top: 3rem;
}

@media (max-width: 650px) {
  .container-left-right {
    flex-direction: column;
  }

  .container-right {
    width: 100%;
  }
  .container-left {
    width: 100%;
  }

  .filter-wrapper {
    flex-direction: column;
  }

  .page-content {
    width: 100%;
    padding: 4px;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}
