* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.calendarWrap {
  /* display: inline-block; */
  position: relative;
  cursor: pointer;

}

.rdrDateRangePickerWrapper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: absolute;
    z-index: 999;
    left: -50%;
    top: 3.5rem;
}

.calendarElement {
  /* position: absolute;
  /* left: 50%; */
  /* transform: translateX(-50%);
  top: 3rem;
  border: 1px solid #ccc;
  z-index: 999;  */
}
.rdrDefinedRangesWrapper {
  display: none;
}
.calender-choose-date {
  font-size: 16px;
  font-weight: 600;
 margin-bottom: 5px;
}
.inputBox {
  font-size: 14.5px;

}