.checkin-checkout-cont {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.to-from-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 30px;
  border-bottom: 1px solid green;
  margin-right: 30px;
}
.total-container {
  padding: 1rem 0;
  background-color: #f8f8f8 !important;
  color: #3b3b3b;
  /* height: 400px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.gross-total-card-price-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  /* margin: 10px; */
}
.gross-room-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px;
  padding: 8px;
}
.gross-room-guest-count-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 6px;
}
.gross-room-guest-count-cont > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
}

.gross-total-tax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 6px;
  font-weight: 600;
  font-size: 14px;
}
.dis-none {
  display: none;
}
.partial-pay-cont > div {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  padding: 0 15px;
}
.total-card-text-style {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #3b3b3b;
}
.text-color-check {
  color: #3b3b3b;
}
/* .check-login-btn {
  width: 90%;
  margin: auto;
  font-size: 16px;
  text-transform: uppercase;
  padding: 6px 0px;
  letter-spacing: 1px;
  font-family: sans-serif;
} */
.check-login-btn {
  border-radius: 4px;
  background-color: var(--secondary-color);
  border: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  /* padding: 16px; */
  width: 90%;
  transition: all 0.5s;
  cursor: pointer;
  padding: 10px 0px;
  text-transform: uppercase;
  /* margin: 36px; */
  box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.7);
}

.check-login-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.check-login-btn:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 9px;
  right: -20px;
  transition: 0.5s;
}

.check-login-btn:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.check-login-btn:hover:after {
  opacity: 1;
  right: 100px;
}

.gross-total-btn {
  display: flex;
  justify-content: center;
}

.gross-total-btn button {
  padding: 8px 14px;
  width: 100%;
  margin: auto;
  margin-top: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background: var(--secondary-color);
}
.coupons-modal-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin: 15px 5px;
  padding: 10px;
  text-align: center;
}
.coupon-remove-div {
  height: 10px;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0 16px;
  margin-top: -5px;
}

@media (max-width: 650px) {
  .total-container {
    margin-top: 50px;
  }
}
