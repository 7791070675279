.booking-part1-cont {
}
.booking-user-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  font-weight: 600;
  background-color: rgb(249, 249, 251);
}
.user-form-fields {
  /* display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 10px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
/* .user-form-fields > div {
    display: flex;
    flex-direction: column;
   
    align-items: flex-start;
}
.user-form-mobile-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-left: 0.7rem;
} */
.input-booking {
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid grey;
}
.booking-user-p {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-top: 10px;
}
.booking-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  /* margin-left: 8px; */
}
.booking-button > button {
  padding: 14px 31px;
  font-size: 16px;
  color: white;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  width: 47%;
}
.booking-button1 {
  /* background-color: white; */
  background: #f8f8f8 !important;
  color: black;
}
.user-details-modifiy-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(249, 249, 251);
}
.booking-user-header1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(249, 249, 251);
}

.modify-button > button {
  padding: 8px 14px;
  font-size: 16px;
  letter-spacing: 1px;
  color: white;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
}

@media (max-width: 650px) {
  .user-form-fields {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  .booking-button > button {
    padding: 14px 31px;
    font-size: 16px;
    color: white;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 4px;
    width: 220px;
  }
  .booking-button {
    display: flex;
    justify-content: center;
    padding: 14px;
  }

  .booking-info-p-tag {
    width: 240px;
    margin: auto;
  }
}
