.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  /* color: black; */
}

/* dropdown.. */

.dropdown-details-user {
  display: none;
  position: absolute;
  /* background-color: #f1f1f1; */
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 95%;
  right: 0;
  background: #fff;
  list-style: none;
}
.card:hover .dropdown-details-user {
  display: block;
}
.dropdown-details-user li:hover {
  background: #eeeff2;
  color: black;
}
.my-booking {
  border-top: 1px solid rgb(235, 233, 233);
}

.dropdown-details-user li {
  font-size: 15px;
  padding: 12px;
  border-bottom: 1px solid rgb(235, 233, 233);
}

/* ....................................  */
.hotel-logo-text {
  background: var(--secondary-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
  font-weight: 600;
}
@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* ...............................................  */
@media screen and (max-width: 768px) {
  .dropdown-details-user li {
    font-size: 12px;
    padding: 9px;
    border-bottom: 1px solid rgb(235, 233, 233);
  }

  .dropdown-details-user {
    left: 25%;
  }
}
/* end dropdown ...  */
