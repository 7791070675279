.guest-container {
  /* display: flex; */
  /* border: 1px solid red; */
  /* align-items: center; */
  padding: 5px;
  cursor: pointer;
  /* padding-top: 9px; */
}

.showed {
  display: block;
}

.hidden {
  display: none;
}
.hero-update-btn.disabled {
  background-color: #ccc; /* Change this to the desired color for disabled state */
  color: black;
  cursor: not-allowed;
  text-decoration: line-through;
}

.guest-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid blue; */
  /* margin-left: 5px;
  margin-bottom: 5px; */
}
.guest-text > p {
  font-weight: 600;
  /* color: #F62A00; */
}
.text-guest {
  display: flex;
  /* border: 1px solid green; */
  font-size: 16px;
}
.text-guest > p:nth-child(1) {
  margin-right: 5px;
}
.container-update-count {
  display: none;
  position: absolute;
  width: 300px;
  top: 32%;
  left: 21%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  z-index: 999;
  background-color: white;
}
.select-text-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 6px;
}
.toggle-display {
  display: block;
}
.adult-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  margin-top: 10px;
}
.main-input-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  margin-top: 10px;
}
.div-inc-dec {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
}
.select-text-button {
  font-size: 16px;
  font-weight: 600;
  /* border: 1px solid red; */
}

.p-guest-style {
  font-size: 14px;
  font-weight: bold;
  color: rgb(59, 59, 59);
}
.hero-update-btn {
  padding: 10px;
  font-size: 12px;
  border: none;
  margin-top: 20px;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 500;
  /* background-color: #F62A00; */
  background-color: var(--secondary-color);
  color: #fff;
  cursor: pointer;
}
/* .hero-update-btn:hover {
  background-color: #9b9b9b;
  color: black;
} */
.guest-icon {
  display: flex;
  justify-content: center;
  font-weight: 500;
  /* margin-bottom:3px; */
}
/* .box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box select {
  background-color: #0563af;
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
} */

@media (max-width: 650px) {
  .container-update-count {
    left: 2%;
  }
}
