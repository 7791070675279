.room-list-container {
  background-color: #f8f8f8;
  /* margin-top: 20px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 6px;
  /* border-top: 2px solid #e85a4f;          */
}

.room-card-left-right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  /* margin: 10px; */
}

.room-card-left {
  width: 33%;
}
.room-card-middle {
  width: 33%;
}
.room-card-right {
  width: 33%;

}
.img-hover-zoom--slowmo img {
  transform-origin: 20% 25%;
  transition: transform 2s, filter 2s ease-in-out;
  /* filter: brightness(50%); */
  cursor: pointer;
}

/* The Transformation */
.img-hover-zoom--slowmo:hover img {
  /* filter: brightness(100%); */
  transform: scale(1.1);
}

.room-image > img {
  width: 90%;
  /* height: fit-content; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  max-height: 150px;
  border-radius: 8px;
  /* border: 1px solid red; */
}

.room-card-price-cont {
  /* border-top: 1px solid black; */
  width: 100%;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /* text-align: right; */
}
.p-text-room1 {
  /* color: #fff; */
  font-size: 16px;
  font-weight: 600;
}
.p-text-room-desc {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;

}

.modal-gallery-cont-photo {
  position: absolute;
  z-index: 99999;
  top: 40%;
}

.room-booking-btn {
  padding: 10px 25px;
}

@media (max-width: 650px) {
  .room-card-left-right {
    padding: 5px;
    text-align: right;
    font-size: 14px;
  }
  .room-booking-btn {
    padding: 6px;
    font-size: 12px !important;
  }
}
