.filter-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem;
    padding-right: 2rem;
    color: black;
    /* background-image: linear-gradient(to right top, #eee0c9, #eee0c9); */
    background-color: #f8f8f8;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    border-radius: 4px;
}

.p-filter-style {
    font-size: 16px;
    font-weight: 600;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
}


@media (max-width: 625px){
    .filter-rooms{
        display: none;
    }

}