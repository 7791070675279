.App {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
body {
  /* background-image: linear-gradient(to bottom, #73aeb3, #649d9c, #578c86, #4b7c71, #416b5c); */
  /* background-image: linear-gradient(to right top, #96b6c5, #adc4ce); */
  /* background-color: #EAE7DC; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  /* Main Content Color */
  --primary-color: white;

  /* Background and Accent Colors */
  --secondary-color: #05152d; /* Dark navy */
  --tertiary-color: #f0f4f8; /* Soft light gray for subtle backgrounds */

  /* Borders */
  --primary-border-color: #05152d; /* Dark border */
  --secondary-border-color: white; /* Light border */
  --tertiary-border-color: #cccccc; /* Neutral light gray border for neutral sections */

  /* Text Colors */
  --primary-text-color: #05152d; /* Main text (dark navy for readability) */
  --secondary-text-color: white; /* Inverted text */
  --tertiary-text-color: #8a8a8a; /* Muted text (for less important content like placeholders) */

  /* Background Colors */
  --background-color: #ffffff; /* Default white background */
  --secondary-background-color: #f7f7f7; /* Light gray background for sections */

  /* Hover and Interactive States */
  --hover-color: #e0e0e0; /* More solid light gray hover effect */
  --highlight-color: #00ffbc; /* Bright green highlight for selected items */
  --link-color: #0088cc; /* Distinct link color (blue) */
  --focus-color: #00ffbc; /* Focus color for keyboard navigation (same as highlight) */

  /* State Colors (Success/Warning/Error) */
  --success-color: #28a745; /* Green for success states */
  --warning-color: #ffc107; /* Yellow for warnings */
  --error-color: #dc3545; /* Red for errors */

  /* Add custom properties for scrollbar colors */
  --scrollbar-thumb-color: #888; /* Default thumb color */
  --scrollbar-track-color: #f1f1f1; /* Default track color */
}
