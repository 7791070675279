.footer-main-container-link {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px 20px;
    color: white;
    font-weight: 600;
}
.brand-logo-container {
    color: #ffffff;
}
.copy-right-container {
    color: #ffffff;
    padding-bottom: 10px;
}
.footer-link-rout-nepal-website {
    text-decoration: none;
    text-transform: capitalize;
    text-align: left;
}
.hotel-desc-addres-cont {
    text-align: left;
    line-height: 23px;
}
.social-media-wrapper {

}

.icon-style-social {
    margin: 2px;
}
.brand-text {
    margin: 5px;
    font-weight: 600;
}


@media (max-width: 650px){
    .footer-main-container-link {
       flex-direction: column;
        width: 100%;
       margin-bottom: 20px;
       padding: 10px 10px;
      
    }
    
    .hotel-desc-addres-cont {
        line-height: 27px;
    }

    .copy-right-container {
        padding: 10px;
    }
  }